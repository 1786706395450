import React from "react";
import Layout from "views/components/Layout";
import imgKlinea1 from "./media/klinea_1.png";
import imgKlinea2 from "./media/klinea_2.png";
import imgKlinea3 from "./media/klinea_3.png";
import imgKlinea4 from "./media/klinea_4.jpg";

class Klinea extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p style={{ textAlign: "center" }}>
                          <img src={imgKlinea4} alt="" width="100%" />
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              fontFamily: "arial, helvetica, sans-serif",
                              fontSize: "12pt"
                            }}
                          >
                            <b>klinea</b>, a global engineering corporation
                            specialized in the pharmaceutical sector. Fully
                            committed to providing high added value in
                            Engineering design, Project Management and Quality
                            Scrutineering Tests
                          </span>
                        </p>
                        <div className="row">
                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgKlinea1}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Engineering</b>
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                KLINEA has a technical team with extensive
                                experience in all disciplines required for the
                                design of pharmaceutical laboratories, always
                                following the ISPE GOOD ENGINEERING PRACTICE
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgKlinea2}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Project Management</b>
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Specialists in the management of large-scale
                                projects and project management at national and
                                international level. Our project managers are
                                governed by the PMBOK Guide, PROJECT MANAGEMENT
                                BODY OF KNOWLEDGE.
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgKlinea3}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Quality Scrutineering Tests</b>
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Multidisciplinary advisors in defining,
                                preparing and supervising quality scrutineering
                                tests. Committed to the application of the
                                standard GOOD MANUFACTURE PRACTICE and the
                                fulfillment of any other quality standard.
                              </span>
                            </p>
                          </div>

                          <div className="col-lg-12">
                            <p>
                              With an extensive{" "}
                              <span style={{ color: "#6EF4CE" }}>
                                {" "}
                                experience{" "}
                              </span>{" "}
                              in engineering and consulting projects around the
                              world. The specialization achieved in the design
                              of customized and global solutions of complex
                              projects allows us to deliver a state of the art
                              project for the pharmaceutical sector, complying
                              with the most demanding regulations.
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <a
                                href="https://klinea.es/home/"
                                style={{ color: "#6EF4CE" }}
                              >
                                www.klinea.es/home{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Klinea;
