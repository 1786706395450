import React from "react";
import Layout from "views/components/Layout";
import imageEdda1 from "./media/edda_1.png";
import imageEdda2 from "./media/edda_2.png";
import imageEdda3 from "./media/edda_3.png";
import imageEdda4 from "./media/edda_4.png";

class Eddapharma extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>

                      <div className="row">
                        <div
                          className="col-lg-6"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <h3>RUEDA FARMA Laboratorios</h3>
                            <br />
                            <p>
                              Rueda Farma was established in 2006 and after
                              several years of transformation… in 2018 we are
                              launching a new company name, EDDA PHARMA, with a
                              clear commitment to future growth both nationally
                              and internationally.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                          <img
                            src={imageEdda1}
                            alt="edda"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6" style={{ paddingRight: 0 }}>
                          <img
                            src={imageEdda2}
                            alt="edda"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div
                          className="col-lg-6"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <p>
                              Our essence is Spanish innovation, the continuous
                              creation of value in all our products and
                              categories, either by improving existing lines or
                              entering new areas and services demanded by
                              today's patients through pharmacies. Edda Pharma
                              launches its slogan, innovating value, its
                              hallmark focused on improving health and
                              wellbeing.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-lg-6"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <h1>Factory</h1>
                            <p>
                              Our factory located in Madrid is authorized by the
                              Spanish Agency for Medicines and Health Products
                              (AEMPS) and complies with the certification of
                              good manufacturing practices (GMPs) which
                              guarantees quality and safety in the manufacture
                              of our products.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                          <img
                            src={imageEdda3}
                            alt="edda"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6" style={{ paddingRight: 0 }}>
                          <img
                            src={imageEdda4}
                            alt="edda"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <div>
                            <p>
                              Rueda Farma was established in 2006 and after
                              several years of transformation… in 2018 we are
                              launching a new company name, EDDA PHARMA, with a
                              clear commitment to future growth both nationally
                              and internationally.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <p style={{ textAlign: "center" }}>
                            <a
                              href="https://eddapharma.com/"
                              style={{ color: "#004A87" }}
                            >
                              <b>www.eddapharma.com</b>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Eddapharma;
