import React from "react";
import Layout from "views/components/Layout";
import imgNetPharmaLab1 from "./media/netpharmalab_1.png";
import imgNetPharmaLab2 from "./media/netpharmalab_2.png";
import imgNetPharmaLab3 from "./media/netpharmalab_3.png";
import imgNetPharmaLab4 from "./media/netpharmalab_4.png";
import imgNetPharmaLab5 from "./media/netpharmalab_5.png";
import imgNetPharmaLab6 from "./media/netpharmalab_6.png";
import imgNetPharmaLab7 from "./media/netpharmalab_7.png";
import imgNetPharmaLab8 from "./media/netpharmalab_8.png";

class Netpharmalab extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p style={{ textAlign: "center" }}>
                          <img src={imgNetPharmaLab1} alt="" width="100%" />
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          <span className="color-705996">Netpharmalab</span> is
                          a Spanish-based company, located in Madrid, whose main
                          mission is customer services providing
                          <span className="color-705996">
                            quality control and distribution of pharmaceutical
                            products
                          </span>
                          as well as the{" "}
                          <span className="color-705996">Research</span> &{" "}
                          <span className="color-705996">Development</span> &{" "}
                          <span className="color-705996">Innovation</span>.
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          The strategy of our organization is based on the
                          compliance with the current regulations and with the
                          requirements of the customers.
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          We have a{" "}
                          <span className="color-705996">
                            team of highly qualified professionals
                          </span>{" "}
                          with extensive experience in the Pharmaceutical
                          Industry. We foster a culture of{" "}
                          <span className="color-705996">leadership</span> and{" "}
                          <span className="color-705996">efficiency</span> to
                          achieve that our objectives become results and to
                          offer quality and reliable services that meet the
                          requirements of our clients, Agencies and our company.
                        </p>
                        <h6 className="color-705996">Services</h6>
                        <div className="row">
                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab2}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Microbiology</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              We have a complete microbiological control
                              service, adapted to the requirements of the
                              customer and the needs of the product.
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab3}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Physicochemical analysis</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              We offer multiple physicochemical techniques and
                              methods adapted and optimized to the product.
                            </p>
                          </div>

                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab4}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Stability studies</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              We provide the possibility of performing stability
                              studies and the storage in our climatic chambers.
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab5}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Galenical Development</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              We carry out galenical developments of different
                              pharmaceutical dosage forms adapted to the
                              requirements of the customer.
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab6}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Consulting services</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              Galenical consulting services and analytical
                              consulting services.
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab7}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Import laboratory</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              At Netpharmalab, we have the authorizations and
                              certification needed as well as more than 2,000
                              sqm of warehouse for the import of finished
                              product from the manufacturer country.
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgNetPharmaLab8}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <b>Toxicology</b>
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              In collaboration with DDALIA GLOBAL SERVICES, we
                              offer a complete service from the toxicological
                              point of view.
                            </p>
                          </div>

                          <div className="col-lg-12">
                            <p style={{ textAlign: "center" }}>
                              <a
                                className="color-705996"
                                href="http://netpharmalab.es/"
                              >
                                www.netpharmalab.es
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Netpharmalab;
