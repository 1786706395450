import React from "react";
import Layout from "views/components/Layout";
import imgInbiotech3 from "./media/inbiotech_3.png";

class Inbiotech extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p style={{ textAlign: "center" }}>
                          <img src={imgInbiotech3} alt="" width="100%" />
                        </p>

                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          <p>
                            Inbiotech, Ltd., Bulgaria, is founded in 1991 by
                            specialists with extensive experience,
                            professionalism and traditions in the Bulgarian
                            pharmaceutical science and manufacturing. The
                            company’s success is associated primarily with the
                            name of Prof. Angel Kirkov, D.Med.Sc., one of the
                            founders of contemporary Bulgarian pharmaceutical
                            industry, long-standing Director General of the
                            Pharmaceutical Alliance “Pharmachim” and teacher in
                            the Faculty of Pharmacy at the Medical University in
                            Sofia, Bulgaria.
                          </p>
                          <p>
                            A team of highly-qualified specialists –
                            pharmacists, doctors, chemists and economists,
                            implements the successful research and manufacturing
                            activity of Inbiotech.
                          </p>
                          <p>
                            Our medicines are characterized by proven
                            therapeutic effect, highest quality and affordable
                            prices. All these factors contribute to the stable
                            presence of the company in the Bulgarian
                            pharmaceutical market.
                          </p>
                          <p>
                            The product list of Inbiotech, Ltd., which is in
                            constant process of expansion and renewal, includes
                            modern and traditional prescription medicines, OTC
                            medicines and nutraceuticals in key therapeutic
                            areas, such as gastroenterology, cardiology,
                            anesthetics and painkillers, rheumatology,
                            endocrinology, pediatry and hepatology. Our product
                            list includes medicines and food supplements
                            targeted towards diseases, such as hypertension,
                            heart failure, ulcer, duodenal ulcer,
                            hyperlipidemia, diseases of the locomotor system, as
                            well as anesthetics and painkillers.
                          </p>
                          <p>
                            Production of our medicinal products and
                            nutraceuticals is achieved in accordance with the
                            Good Manufacturing Practice (GMP).
                          </p>
                          <p>
                            The leading principles in our choice of drug
                            strategy are therapeutic, social and economic
                            effectiveness. By means of marketing and management
                            we close the full cycle from scientific research and
                            production process to realization of a drug on the
                            market and monitoring its effectiveness, reception
                            by patients and doctors, as well as its marketing.
                          </p>
                          <p>
                            Recognition of the contribution of Inbiotech Ltd. to
                            drug policy and business development in Bulgaria are
                            numerous prestigious awards for its successful
                            production activity and high-socio-economic
                            effectiveness. The awards, which the company has
                            been repeatedly awarded with are from the Bulgarian
                            Chamber of Commerce & Industry, as well as from
                            various Bulgarian scientific organizations.
                          </p>
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <p style={{ textAlign: "center" }}>
                            <a
                              href="httpsß://www.inbiotech-bg.com"
                              style={{ color: "#0FC146" }}
                            >
                              <b>www.inbiotech-bg.com</b>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Inbiotech;
