import React from "react";
import Layout from "views/components/Layout";
import pano from "./media/pano.2.jpg";
import OwlCarousel from "react-owl-carousel2";
import image1 from "./media/1.png";
import image2 from "./media/2.png";
import image3 from "./media/3.png";
import image4 from "./media/4.png";
import image5 from "./media/5.png";
import image6 from "./media/6.png";
import image7 from "./media/7.png";
import "./owl.css";

let quantityImage = 3;
if (window.innerWidth > 1000) quantityImage = 4;
if (window.innerWidth < 700) quantityImage = 2;

const options = {
  items: quantityImage,
  rewind: true,
  autoplay: true,
  autoplayTimeout: 2000,
  nav: false
};

class Homepage extends React.PureComponent {
  render() {
    return (
      <Layout>
        <div className="container">
          <div className="row align-items-center" style={{ marginTop: 50 }}>
            <div className="col-12 col-lg-12">
              <img
                src={pano}
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: 50
                }}
                alt="orioled"
              />
            </div>
            <div className="col-12 col-lg-12" style={{ marginBottom: 50 }}>
              <OwlCarousel ref="car" options={options}>
                <div>
                  <img
                    src={image1}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="edda pharma"
                  />
                </div>
                <div>
                  <img
                    src={image2}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="inbiotech"
                  />
                </div>
                <div>
                  <img
                    src={image3}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="klinea"
                  />
                </div>
                <div>
                  <img
                    src={image4}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="map biopharma"
                  />
                </div>
                <div>
                  <img
                    src={image5}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="netpharmalab"
                  />
                </div>
                <div>
                  <img
                    src={image6}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="pharmalex"
                  />
                </div>
                <div>
                  <img
                    src={image7}
                    style={{
                      height: 145,
                      width: "auto"
                    }}
                    alt="qualipharma"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default Homepage;
