import React from "react";

class Footer extends React.PureComponent {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 sm-margin-30px-bottom">
              <p
                style={{
                  color: "#BD9048",
                  fontSize: 22,
                  paddingLeft: 16,
                  fontWeight: 700
                }}
              >
                ORIOLED INTERNATIONAL PTE LTD & TV TPI CO LTD
              </p>
              <ul className="footer-list" style={{ paddingLeft: 16 }}>
                <li>
                  <span className="vertical-align-top font-size18">
                    <i className="fas fa-map-marker-alt text-theme-color"></i>
                  </span>
                  <span className="width-85 vertical-align-top padding-10px-left">
                    72 Binh Gia Street, Ward 13, Tan Binh District, Ho Chi Minh
                    City, Vietnam - 760000
                  </span>
                </li>
                <li>
                  <span className="vertical-align-top font-size18">
                    <i className="fas fa-mobile-alt text-theme-color"></i>
                  </span>
                  <span className="width-85 vertical-align-top padding-10px-left">
                    +84 2838 663399
                  </span>
                </li>
                <li>
                  <span className="vertical-align-top font-size18">
                    <i className="far fa-envelope text-theme-color"></i>
                  </span>
                  <span className="width-85 vertical-align-top padding-10px-left">
                    tv.tpi@orioled.com
                  </span>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-4">
              <br />
              <br />
              <p className="text-light-gray margin-20px-bottom">
                Sign up to newsletter and stay in touch.
              </p>
              <form _lpchecked="1">
                <div className="form-group footer-subscribe">
                  <input
                    className="form-control"
                    placeholder="Enter your email here..."
                    aria-label="Enter your email here..."
                    type="text"
                    value=""
                    readOnly
                  />
                  <button className="butn theme grey-hover newsletter-subscribe-button">
                    <span>
                      <i className="fas fa-envelope"></i>
                    </span>
                  </button>
                </div>
                <div
                  className="newsletter-result text-center text-white w-100"
                  id="newsletter-result-block"
                ></div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-bar">
          <div className="container">
            <p>
              <span>&copy;ORIOLED 2020</span>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
