import React from "react";
import Layout from "views/components/Layout";
import image1 from "../Homepage/media/1.png";
import image2 from "../Homepage/media/2.png";
import image3 from "../Homepage/media/3.png";
import image4 from "../Homepage/media/4.png";
import image5 from "../Homepage/media/5.png";
import image6 from "../Homepage/media/6.png";
import image7 from "../Homepage/media/7.png";

const data = [
  {
    url: "/incorporated-member/klinea",
    title: "KLINEA",
    content:
      "Design of pharmaceutical laboratories, following the ISPE GOOD ENGINEERING PRACTICE in all phases of a project, quality checks.",
    image: image1
  },
  {
    url: "/incorporated-member/qualipharma",
    title: "QUALIPHARMA",
    content:
      "GMP & FDA Consulting - our secret to success is the enthusiasm that we put into all of our projects",
    image: image2
  },
  {
    url: "/incorporated-member/netpharmalab",
    title: "NETPHARMALAB",
    content:
      "We provide Quality Control Services, Research & Development and Innovation as well as Distribution pharmaceutical products",
    image: image3
  },
  {
    url: "/incorporated-member/pharmalex",
    title: "PHARMALEX",
    content:
      "One of the largest specialized providers of Development Consulting, Regulatory Affairs, Quality Management & Compliance and Pharmacovigilance.",
    image: image4
  },
  {
    url: "/incorporated-member/mapbiopharma",
    title: "MAPBIOPHARMA",
    content:
      "Raw materials and Finished Dosage Forms Suppliers for the Pharmaceutical, Veterinary and Nutraceutical Industry",
    image: image5
  },
  {
    url: "/incorporated-member/inbiotech",
    title: "INBIOTECH",
    content:
      "The product list of Inbiotech, which is in constant process of expansion and renewal, includes modern and traditional prescription medicines, OTC medicines and nutraceuticals.",
    image: image6
  },
  {
    url: "/incorporated-member/eddapharma",
    title: "EDDA PHARMA",
    content:
      "The Spanish innovation, continuous creation of value in all products and categories, either by improving existing lines or entering new areas and services demanded by patients through pharmacies.",
    image: image7
  }
];

class Partnering extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-9 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                {data.map(item => (
                  <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-lg-4">
                      <img
                        src={item.image}
                        alt="partnering"
                        style={{
                          width: "100%",
                          height: "auto"
                        }}
                      />
                    </div>
                    <div className="col-lg-7" style={{ position: "relative" }}>
                      <h4>{item.title}</h4>
                      <p
                        className="custom-blog-text"
                        style={{ textAlign: "justify" }}
                      >
                        {item.content}
                      </p>
                      <div
                        class="text-left margin-10px-top"
                        style={{ position: "absolute", bottom: 0, right: 0 }}
                      >
                        <a
                          href={item.url}
                          title={item.title}
                          class="butn small"
                        >
                          <span>Read more</span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Partnering;
