import React from "react";
import Layout from "views/components/Layout";
import imgPharmalex1 from "./media/pharmalex_1.png";

class Pharmalex extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p
                          style={{
                            textAlign: "center",
                            position: "relative",
                            color: "white"
                          }}
                        >
                          <img src={imgPharmalex1} alt="" width="100%" />
                          <span className="text-centered-image font-size-40 top-66-percent">
                            PHARMALEX
                          </span>
                        </p>
                        <h4>
                          Pharmaceutical industry experts with flexibility
                        </h4>
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          These principles form the values and the business
                          approach that PharmaLex was founded on, and are still
                          true today. PharmaLex prides itself on becoming an
                          extension of your team; with the quick turnaround,
                          expertise and flexibility needed to support your
                          goals.
                        </p>

                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          PharmaLex is one of the largest specialized providers
                          of Development Consulting, Regulatory Affairs, Quality
                          Management & Compliance and Pharmacovigilance,
                          Epidemiology & Risk Management worldwide. Our GLOCAL
                          (GLObal reach and loCAL presence) teams of experts can
                          take you through early strategic planning activities
                          and non-clinical requirements to clinical development,
                          through regulatory submission processes and finally
                          guide you to market approval and product maintenance
                          post-launch activities.
                        </p>
                        <h4> PharmaLex focus on </h4>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <p style={{ textAlign: "justify" }}>
                            Discovery Phase to Market Authorization
                            <br />
                            Post Market Product Maintenance
                            <br />
                            Leading Technological Innovation
                            <br />
                            Development Consulting
                            <br />
                            Regulatory Affairs
                            <br />
                          </p>
                        </div>

                        <div className="col-lg-6">
                          <p style={{ textAlign: "justify" }}>
                            Quality Management and Compliance
                            <br />
                            Pharmacovigilance, Epidemiology & Risk Management
                            <br />
                            Medical Device Services
                            <br />
                            Brexit
                            <br />
                          </p>
                        </div>

                        <div className="col-lg-12">
                          <p style={{ textAlign: "center" }}>
                            <a
                              href="https://www.pharmalex.com/"
                              style={{ color: "#1F394A" }}
                            >
                              <b>www.pharmalex.com</b>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Pharmalex;
