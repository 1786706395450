import React from "react";
import Layout from "views/components/Layout";
import imgQualipharma1 from "./media/qualipharma_1.png";
import imgQualipharma2 from "./media/qualipharma_2.png";
import imgQualipharma3 from "./media/qualipharma_3.png";
import imgQualipharma4 from "./media/qualipharma_4.png";
import imgQualipharma5 from "./media/qualipharma_5.png";
import imgQualipharma6 from "./media/qualipharma_6.png";

class Qualipharma extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p
                          style={{
                            textAlign: "center",
                            position: "relative",
                            color: "white"
                          }}
                        >
                          <img src={imgQualipharma1} alt="" width="100%" />
                          <span class="text-centered-image font-size-40">
                            QUALIPHARMA
                          </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              fontFamily: "arial, helvetica, sans-serif",
                              fontSize: "12pt"
                            }}
                          >
                            Quality Pharmaceutical Consulting S.L. (QUALIPHARMA)
                            is a consulting company created in 2001 and
                            specialized in GMP&GDP advisory and consultancy
                            services for healthcare and pharmaceutical sectors.
                          </span>
                        </p>
                        <div className="row">
                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgQualipharma2}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              {" "}
                              <b> VALIDATIONS </b>{" "}
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Cleaning Validation <br />
                                Validation of Reception, Storage and Dispatch
                                Process
                                <br />
                                Production Process Validation
                                <br />
                                Analytical Method Validation
                                <br />
                                Computerized System Validation
                                <br />
                                Pharmaceutical Process Validation
                                <br />
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgQualipharma3}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              {" "}
                              <b> QUALIFICATIONS </b>{" "}
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Clean Room Qualification (HVAC) <br />
                                Qualification of Particle Counters Monitoring
                                Installations
                                <br />
                                Thermal Equipment Qualification
                                <br />
                                GMP Fluids Qualification
                                <br />
                                CIP/SIP Cleaning System Qualification
                                <br />
                                Production Equipment Qualification
                                <br />
                                Analytical Instrument Qualification
                                <br />
                              </span>
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgQualipharma4}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              {" "}
                              <b> CONSULTING </b>{" "}
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Risk Assessment Experts
                                <br />
                                Crisis Avoidance-Rescue Plan
                                <br />
                                Experts in Cross-Contamination
                                <br />
                                GMP Audits
                                <br />
                                ICH Q3D Implementation-Elemental Impurities
                                <br />
                                Analytical Development
                                <br />
                              </span>
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgQualipharma5}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              {" "}
                              <b> CALIBRATIONS </b>{" "}
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Particle Counters Calibrations
                                <br />
                                Measuring Instruments
                                <br />
                                Calibrations
                                <br />
                              </span>
                            </p>
                          </div>

                          <div className="col-lg-4">
                            <p style={{ textAlign: "center" }}>
                              <img
                                src={imgQualipharma6}
                                alt=""
                                height="100px"
                                width="100px"
                              />
                            </p>
                            <p style={{ textAlign: "center" }}>
                              {" "}
                              <b> TRAINING </b>{" "}
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                  fontSize: "12pt"
                                }}
                              >
                                Qualipharma offers training courses provided by
                                both internal staff members and skilled
                                collaborators.
                              </span>
                            </p>
                          </div>

                          <div className="col-lg-12">
                            <p>
                              With high prestige and recognition given by AEMPS
                              and our clients, Qualipharma is one of the
                              strongest companies in the sector. Focus on
                              building the excellence, we get fully engaged with
                              every project and commit all our energies to
                              become the best ally of all those companies that
                              Qualipharma collaborate with.
                            </p>
                            <p style={{ textAlign: "center" }}>
                              <a
                                href="https://qualipharmagroup.com/"
                                style={{ color: "#OD75BC" }}
                              >
                                <b>www.qualipharmagroup.com</b>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Qualipharma;
