import React, { Component } from "react";
import { Switch, Route } from "react-router";
import routes from "routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./views/components/Layout/_footer.scss";
import "./views/components/Layout/_header.scss";

class App extends Component {
  render() {
    return (
      <Switch>
        {routes.map((route, i) => (
          <Route
            exact
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            path={route.path}
            render={props => (
              <React.Fragment>
                <route.component
                  isShowMenu={route.isShowMenu}
                  isHideBanner={route.isHideBanner}
                  {...props}
                />
              </React.Fragment>
            )}
          />
        ))}
      </Switch>
    );
  }
}

export default App;
