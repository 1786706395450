import Homepage from "views/pages/Homepage";
import Company from "views/pages/Company";
import Contact from "views/pages/Contact";
import Partnering from "views/pages/Partnering";
import EddaPharma from "views/pages/Partnering/eddapharma";
import Inbiotech from "views/pages/Partnering/inbiotech";
import Klinea from "views/pages/Partnering/klinea";
import Mapbiopharma from "views/pages/Partnering/mapbiopharma";
import Pharmalex from "views/pages/Partnering/pharmalex";
import Netpharmalab from "views/pages/Partnering/netpharmalab";
import QualiPharma from "views/pages/Partnering/qualipharma";

export default [
  { path: "/", component: Homepage },
  { path: "/incorporated-member", component: Partnering },
  { path: "/incorporated-member/klinea", component: Klinea },
  { path: "/incorporated-member/qualipharma", component: QualiPharma },
  { path: "/incorporated-member/netpharmalab", component: Netpharmalab },
  { path: "/incorporated-member/pharmalex", component: Pharmalex },
  { path: "/incorporated-member/mapbiopharma", component: Mapbiopharma },
  { path: "/incorporated-member/inbiotech", component: Inbiotech },
  { path: "/incorporated-member/eddapharma", component: EddaPharma },
  { path: "/contact", component: Contact },
  { path: "/company", component: Company }
];
