import React from "react";
import Layout from "views/components/Layout";

class Contact extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs custom-blogs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content" style={{ paddingLeft: "1rem" }}>
                      <div className="post-meta">
                        <div
                          className="post-title apply-text-color"
                          style={{ marginBottom: 10 }}
                        >
                          <h5>CONTACT</h5>
                        </div>
                      </div>
                      <div className="post-cont apply-text-color">
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              fontFamily: "arial, helvetica, sans-serif",
                              fontSize: "12pt"
                            }}
                          >
                            Once you need more information or any support.
                            Please feel free to contact us:
                          </span>
                        </p>
                        <ul className="footer-list" style={{ paddingLeft: 10 }}>
                          <li>
                            <div style={{ width: 30, float: "left" }}>
                              <i className="fas fa-mobile-alt apply-text-color"></i>
                            </div>
                            <span
                              className="d-inline-block width-85 vertical-align-top apply-text-color"
                              style={{
                                fontFamily: "arial, helvetica, sans-serif",
                                fontSize: "12pt"
                              }}
                            >
                              +84 2838 663399
                            </span>
                          </li>
                          <li>
                            <div style={{ width: 30, float: "left" }}>
                              <i className="far fa-envelope apply-text-color"></i>
                            </div>
                            <span className="d-inline-block width-85 vertical-align-top apply-text-color">
                              tv.tpi@orioled.com
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Contact;
