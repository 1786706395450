import React from "react";
import Layout from "views/components/Layout";
import Pano from "../Homepage/media/pano.2.jpg";

class Company extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs custom-blogs">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p style={{ textAlign: "center" }}>
                          <img src={Pano} alt="" width="100%" />
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          <span
                            style={{
                              fontFamily: "arial, helvetica, sans-serif",
                              fontSize: "12pt"
                            }}
                          >
                            ORIOLED & TV TPI, as a group of companies was
                            established in Singapore and Vietnam - merging
                            pharmaceutical and financial companies that have
                            more than 20 years of experience in pharmaceutical
                            market. We cover all aspects of business from
                            logistics to regulatory to financial to sales to
                            consultancy. ORIOLED & TV TPI is the representative,
                            distributor, preferred and reliable partner of many
                            world-wide companies from US, Europe and Japan to
                            South East Asia.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Company;
