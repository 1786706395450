import React from "react";
import Layout from "views/components/Layout";
import imgMapbiopharma1 from "./media/mapbiopharma_1.png";

class Mapbiopharma extends React.PureComponent {
  render() {
    return (
      <Layout>
        <section className="blogs">
          <div className="container">
            <div className="row" style={{ color: "#1f3864" }}>
              <div className="col-lg-12 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                <div className="posts">
                  <div className="post">
                    <div className="content">
                      <div className="post-meta">
                        <div
                          className="post-title"
                          style={{ marginBottom: 10 }}
                        ></div>
                      </div>
                      <div className="post-cont">
                        <p style={{ textAlign: "center" }}>
                          <img src={imgMapbiopharma1} alt="" width="100%" />
                        </p>
                        <h4>MAPBIOPHARMA</h4>
                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          Raw materials and Finished Dosage Forms Suppliers for
                          the Pharmaceutical, Veterinary and Nutraceutical
                          Industry.
                        </p>

                        <p
                          style={{
                            textAlign: "justify",
                            fontFamily: "arial, helvetica, sans-serif",
                            fontSize: "12pt"
                          }}
                        >
                          Human APIs: collaborate with Vivatis Pharma -
                          exclusive right in Spain and Portugal Veterinary APls
                          <br />
                          Finished Dosage Forms: Generics, OTCs, Nutritional
                          Products, Dermatology
                          <br />
                          GMP activities: Rueda pharma
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <p style={{ textAlign: "center" }}>
                            <a
                              href="http://www.mapbiopharma.es/en"
                              style={{ color: "#3C72B6" }}
                            >
                              <b>www.mapbiopharma.es/en</b>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Mapbiopharma;
