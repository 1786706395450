import React from "react";
import logoHeader from "./media/logo_header.png";
import logoHeader1 from "./media/logo_header_1.png";
import $ from "jquery";

class Header extends React.PureComponent {
  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);
    $(".navbar-toggler").click(function() {
      $(this).toggleClass("menu-opened");
      $(".ml-auto").toggleClass("open-items");
    });

    $(".submenu-button").click(function() {
      $(".sub-menu").toggleClass("open-items");
    });
  }

  handleOnScroll = () => {
    if (window.scrollY > 45) {
      $("header")
        .addClass("scrollHeader")
        .removeClass("fixedHeader");
    } else {
      $("header")
        .addClass("fixedHeader")
        .removeClass("scrollHeader");
    }
  };

  render() {
    const linkURL = [
      {
        url: "/incorporated-member/klinea",
        text: "KLINEA"
      },
      {
        url: "/incorporated-member/qualipharma",
        text: "QUALIPHARMA"
      },
      {
        url: "/incorporated-member/netpharmalab",
        text: "NETPHARMALAB"
      },
      {
        url: "/incorporated-member/pharmalex",
        text: "PHARMALEX"
      },
      {
        url: "/incorporated-member/mapbiopharma",
        text: "MAPBIOPHARMA"
      },
      {
        url: "/incorporated-member/inbiotech",
        text: "INBIOTECH"
      },
      {
        url: "/incorporated-member/eddapharma",
        text: "EDDA PHARMA"
      }
    ];

    return (
      <header className="header-style8 fixedHeader">
        <div className="navbar-default">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-12">
                <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg navbar-light no-padding current">
                    <div className="navbar-header navbar-header-custom col-lg-6 col-sm-12">
                      <a
                        href="/"
                        className="navbar-brand logodefault"
                        style={{ marginRight: 9 }}
                      >
                        <img alt="ORIOLED" src={logoHeader} />
                      </a>
                      <a href="/" className="navbar-brand logodefault">
                        <img
                          alt="ORIOLED"
                          src={logoHeader1}
                          style={{ paddingTop: 11, maxHeight: 36 }}
                        />
                      </a>
                    </div>
                    <div
                      className="navbar-toggler"
                      style={{ background: "black" }}
                    ></div>
                    <ul className="navbar-nav ml-auto" id="nav">
                      <li>
                        {" "}
                        <a href="/company" className="apply-text-color">
                          {" "}
                          <span className="apply-text-color">COMPANY</span>{" "}
                        </a>{" "}
                      </li>
                      <li className="has-sub active">
                        <span className="submenu-button"></span>{" "}
                        <a
                          href="/incorporated-member"
                          className="apply-text-color"
                        >
                          <span className="apply-text-color">
                            INCORPORATED MEMBER
                          </span>
                        </a>
                        <ul className="sub-menu">
                          {linkURL.map(item => (
                            <li key={item.text}>
                              <a href={item.url} className="apply-text-color">
                                <span className="apply-text-color">
                                  <b>{item.text}</b>
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        {" "}
                        <a href="/contact" className="apply-text-color">
                          {" "}
                          <span className="apply-text-color">CONTACT</span>{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
